import moment from "moment";
import { publicFilesUrl } from "../constants";

/**
 * Set registration company type in localStorage
 * @param {number} type - 1 is STARTUP, 2 is IT COMPANY
 */
export const setRegistrationType = (type) => localStorage.setItem("registrationType", JSON.stringify(type));

/**
 * Format budget number value to string
 * @param {number} budget - value of budget
 */
export const formatBudget = (budget) => {
  switch (budget) {
    case 5000:
      return "Less then $5,000";
    case 15000:
      return "Up to $15,000";
    case 35000:
      return "Up to $35,000";
    case 75000:
      return "Up to $75,000";
    case 100000:
      return "Up to $100,000";
    case 200000:
      return "Up to $200,000";
    default:
      break;
  }
};

/**
 * Format project start value
 * @param {string} value - value of budget
 */
export const formatProjectStart = (value) => {
  switch (value) {
    case "asap":
      return "ASAP";
    case "nextMonth":
      return "Next month";
    case "nextQuartal":
      return "Next Quartal";
    default:
      break;
  }
};

/**
 * normalize URL
 * @param {string} url
 */
export const normalizeURL = (url) => {
  if (url && url.indexOf("http") === -1) {
    return `https://${url}`;
  } else {
    return url;
  }
};

export const isSubscribed = (stripe_subscription_expires) => {
  return moment(stripe_subscription_expires).isAfter(moment());
};

export const printLeadPrice = (fixed_price, percentage) => {
  const priceParts = [];

  if (fixed_price) {
    priceParts.push(`$${fixed_price}`);
  }

  if (percentage) {
    priceParts.push(`${percentage}%`);
  }

  return priceParts.join(' + ');
};

export const buildImageLink = imageURL => `${publicFilesUrl}public/img/${imageURL}`;

export const buildTelegramLink = telegramNumber => {
  if (telegramNumber.includes('https://')) {
    return telegramNumber;
  }

  const numberWithPlus = telegramNumber.includes('+') ? telegramNumber : `+${telegramNumber}`;
  return `https://t.me/${numberWithPlus}`;
};

export const buildWhatsappLink = whatsappNumber => {
  if (whatsappNumber.includes('https://')) {
    return whatsappNumber;
  }

  const numberWithoutPlus = whatsappNumber.includes('+') ? whatsappNumber.substr(1) : whatsappNumber;
  return `https://wa.me/${numberWithoutPlus}`;
};

export const getRandomArrayItem = (array) => {
  const index =  Math.floor(Math.random() * array.length);
  return array[index];
};

export const isAfterYesterday = (date) => {
  return moment().subtract(1,'days').isBefore(date);
};

export const isAfterNow = (date) => {
  return moment(date).isAfter();
};

export const insertLinks = (message) => {
  const urlRegex = /https?:\/\/[^\s]+/g;

  return message.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
};