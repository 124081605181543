import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Verified from "../common/Verified/Verified";
import { printLeadPrice } from "../../utils/utils";
import { siteNameForTitle } from "../../constants/global-constants";

import styles from "./ActivityStats.module.css";

const ActivityStats = ({ stats, activity_stats }) => {

  const renderDeveloperLinks = (developerList) => {
    return developerList.map((developer, index) => ([
      index > 0 && ', ',
      <NavLink to={`/developer/${developer.id}`} key={developer.id}>
        {developer.name}&nbsp;/&nbsp;{developer.country}
      </NavLink>
    ]))
  };

  const renderCompaniesLinks = (companiesList) => {
    return companiesList.map((company, company_index) => {
      const isCompany = company.type === 2 && !company.is_single_developer;
      const detailsPage = isCompany ? 'company' : 'outsource-company';

      // company.name is company.company_name. Don't change it
      return (
        <div key={company.id}>
          <NavLink to={`/${detailsPage}/${company.id}`} className={styles.company} key={company_index}>
            {company.name || company.representative_name}, {company.country}{" "}
            {company.verified ? <Verified/> : ''}
          </NavLink>
        </div>
      );
    });
  };

  const renderTalentsLinks = (talentsList) => {
    return talentsList.map((talent) => {
      const talentDataParts = [talent.country];
      if (talent.position_level_name) {
        talentDataParts.push(talent.position_level_name);
      }

      if (talent.job_title_name) {
        talentDataParts.push(talent.job_title_name);
      }

      if (talent.additional_job_title_name) {
        talentDataParts.push(talent.additional_job_title_name);
      }

      if (talent.monthly_rate) {
        talentDataParts.push(`Salary: $${talent.monthly_rate}`);
      }

      const talentName = `${talent.first_name} ${talent.last_name[0].toUpperCase()}.`;
      const talentPartsString = talentDataParts.join(', ');

      if (talent.profile_fill_step < 5) {
        return (
          <div key={talent.id}>
            {talentName}, {talentPartsString}
          </div>
        )
      }

      return (
        <div key={talent.id}>
          <NavLink to={`/talent/${talent.id}`} className={styles.company}>
            {talentName}
          </NavLink>, {talentPartsString}
        </div>
      );
    });
  };

  let statDates = [];

  if (activity_stats) {
    statDates = Object.keys(activity_stats).sort().reverse();
  }

  // console.log('===statDates', statDates);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Daily updates | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Daily updates</h1>
      {stats && (
        <div className={styles.summary}>
          <span className={styles.summary_item}>Сompanies: {stats.companiesCount}</span>
          <span className={styles.summary_item}>Developers on the bench: {stats.developersCount}</span>
          <span className={styles.summary_item}>Talents: {stats.talentsCount}</span>
        </div>
      )}

      <div className={styles.activity_stats}>
        {statDates.map((date) => {
          const dayStat = activity_stats[date];
          console.log('===dayStat', dayStat);

          return (
            <div className={styles.activity_stats_item} key={date}>
              <div className={styles.day}>{date}</div>

              <div className={styles.bench_wrapper}>
                {dayStat.companies.length > 0 && (
                  <div className={styles.companies_wrapper}>
                    <div className={styles.label}>Companies:</div>
                    <div className={`${styles.companies}`}>
                      {renderCompaniesLinks(dayStat.companies)}
                    </div>
                  </div>
                )}

                {dayStat?.talents?.length > 0 && (
                  <div className={styles.companies_wrapper}>
                    <div className={styles.label}>
                      New Talents:
                    </div>
                    <div className={styles.companies}>
                      {renderTalentsLinks(dayStat.talents)}
                    </div>
                  </div>
                )}

                {dayStat.projects.length > 0 && (
                  <div className={styles.companies_wrapper}>
                    <div className={styles.label}>
                      New Projects:
                    </div>
                    <div className={styles.companies}>
                      {dayStat.projects.map((project) => (
                        <div className={styles.developer} key={project.id}>
                          {project.name}, ${project.budget}, Client from: {project.country}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {dayStat.deletedProjects.length > 0 && (
                  <div className={styles.companies_wrapper}>
                    <div className={styles.label}>
                      Projects Update:
                    </div>
                    <div className={styles.companies}>
                      {dayStat.deletedProjects.map((project) => (
                        <div className={styles.developer} key={project.id}>
                          A contractor for the project "{project.name}" has been found.
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {dayStat.talentSearches.length > 0 && (
                  <div className={styles.developers_wrapper}>
                    <div className={styles.label}>Talent Search:</div>
                    <div className={`${styles.companies}`}>
                      {dayStat.talentSearches.map((searchData) => (
                        <div className={styles.developer} key={searchData.id}>
                          Talent Search: {searchData.position_level_name}, {searchData.job_title_name},
                          Salary: ${searchData.rate_to}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {dayStat.developers.length > 0 && (
                  <div className={styles.developers_wrapper}>
                    <div className={styles.label}>Developers:</div>
                    <div className={`${styles.developers}`}>
                      {dayStat.developers.map((developerStat, developer_index) => (
                        <div className={styles.developer} key={developer_index}>
                          {developerStat.job_title} {developerStat.position_level}{" "}
                          {`(+${developerStat.amount}: `} {renderDeveloperLinks(developerStat.developer_list)}{')'}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.customers_wrapper}>
                {dayStat.clients.length > 0 && (
                  <div className={styles.startup_wrapper}>
                    <div className={styles.label}>Clients:</div>
                    <div className={`${styles.startups}`}>
                      +{dayStat.clients.length} (
                      {dayStat.clients.map((clients, startup_index) => (
                        <span className={styles.clients} key={startup_index}>
                          {startup_index === 0 ? '' : ', '}{clients.country}
                        </span>
                      ))}
                      )
                    </div>
                  </div>
                )}

                {dayStat.searches.length > 0 && (
                  <div className={styles.searches_wrapper}>
                    <div className={styles.label}>Searches:</div>
                    <div className={styles.searches}>
                      {dayStat.searches.map((search, search_index) => (
                        <div className={styles.search} key={search_index}>
                          {search.job_title}, {search.position_level}
                          {search.experience && `, ${search.experience} ${search.experience > 1 ? "years" : "year"} exp.`}
                          {search.skill_names && `; ${search.skill_names.split(",", 3).join(", ")}`}{' '}
                          {search.cerf_level && (
                            <span className={styles.eng_level_wrapper}>
                              English: <span className={styles.eng_level}>{search.cerf_level}</span>
                            </span>
                          )}
                          {search.rate && `, max rate - $${search.rate}/h`}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {dayStat.leads.length > 0 && (
                  <div className={styles.searches_wrapper}>
                    <div className={styles.label}>New Leads:</div>
                    <div className={styles.searches}>
                      {dayStat.leads.map((lead, index) => (
                        <div className={styles.search} key={index}>
                          {lead.country}, {lead.project_type}, {lead.project_name},
                          Price:&nbsp;{printLeadPrice(lead.fixed_price, lead.percentage)},{' '}
                          <span className={styles.nowrap}>Date start: {lead.start}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {dayStat.closedLeads.length > 0 && (
                  <div className={styles.searches_wrapper}>
                    <div className={styles.label}>Lead Updates:</div>
                    <div className={styles.searches}>
                      {dayStat.closedLeads.map((lead, index) => (
                        <div className={styles.leadUpdates} key={index}>
                          <span>
                            {lead.country}, {lead.project_name}, {lead.project_type}, Price: {printLeadPrice(lead.fixed_price, lead.percentage)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default ActivityStats;
