import React, { Component } from "react";
import groupByTime from "group-by-time";
import moment from "moment";
import { notification } from "antd";

import {
  chatFileSizeLimit,
  chatFilesLimit,
  chatFileTypesExtensionList,
  chatFileExtensions,
  chatFileInputAccept,
} from '../../constants/global-constants';
import Message from "./Message";

import styles from "./Chat.module.css";

const fileSizeLimitInBytes = chatFileSizeLimit * 1024 * 1024;

class ChatMessages extends Component {
  messagesWrapper = React.createRef();

  state = {
    scroll_height: 0,
    scroll_top: 0,
    initial_loading: false,
    selected_files: []
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("--------------------------------");
    // console.log(this.messagesWrapper.scrollHeight);
    // console.log(this.messagesWrapper.scrollTop);
    // console.log(this.messagesWrapper.clientHeight);

    // initial scroll to bottom
    if (
      !this.state.initial_loading &&
      this.props.chat_messages[this.props.chat_id] &&
      this.props.chat_messages[this.props.chat_id].length > 1
    ) {
      this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
      this.setState({
        ...this.state,
        initial_loading: true,
      });
    }

    let last_message_date = 0;
    if (this.props.chat_messages[this.props.chat_id]) {
      last_message_date = this.props.chat_messages[this.props.chat_id][
        this.props.chat_messages[this.props.chat_id].length - 1
      ];
      if (last_message_date && last_message_date.created) {
        last_message_date = last_message_date.created;
      }
    }

    let prevProps_last_message_date = 0;
    if (prevProps.chat_messages[this.props.chat_id]) {
      prevProps_last_message_date =
        prevProps.chat_messages[this.props.chat_id][prevProps.chat_messages[this.props.chat_id].length - 1];
      if (prevProps_last_message_date && prevProps_last_message_date.created) {
        prevProps_last_message_date = prevProps_last_message_date.created;
      }
    }

    last_message_date = Date.parse(last_message_date);
    prevProps_last_message_date = Date.parse(prevProps_last_message_date);

    // keep scroll position when old messages loaded
    if (
      prevProps.chat_messages[this.props.chat_id] &&
      this.props.chat_messages[this.props.chat_id] &&
      prevProps.chat_messages[this.props.chat_id].length !== this.props.chat_messages[this.props.chat_id].length &&
      last_message_date === prevProps_last_message_date
    ) {
      this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight - prevState.scroll_height;
      this.setState({
        ...this.state,
        scroll_top: this.messagesWrapper.scrollHeight - prevState.scroll_height,
        scroll_height: this.messagesWrapper.scrollHeight,
      });
    }

    // scroll to bottom if new messages loaded
    if (
      prevProps.chat_messages[this.props.chat_id] &&
      this.props.chat_messages[this.props.chat_id] &&
      prevProps.chat_messages[this.props.chat_id].length !== this.props.chat_messages[this.props.chat_id].length &&
      this.messagesWrapper.scrollHeight > this.messagesWrapper.clientHeight &&
      this.messagesWrapper.scrollTop + this.messagesWrapper.clientHeight === prevState.scroll_height
    ) {
      this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
      this.setState({
        ...this.state,
        scroll_top: this.messagesWrapper.scrollHeight - prevState.scroll_height,
        scroll_height: this.messagesWrapper.scrollHeight,
      });
    }

    // get current chat
    let current_chat;
    if (this.props.chat_list && this.props.chat_id) {
      current_chat = this.props.chat_list.filter((item) => {
        return item.chat_id === this.props.chat_id;
      });
    }
    // console.log(current_chat);
    // set chat seen
    if (
      current_chat?.[0] &&
      current_chat[0].status === 0 &&
      this.messagesWrapper.scrollHeight <= (this.messagesWrapper.scrollTop + this.messagesWrapper.clientHeight + 5)
    ) {
      this.props.sendChatSeen(this.props.chat_id, () => this.props.socketRead({
        chat_id: this.props.chat_id,
        from: this.props.user.id,
        to: this.props.chat_user?.company_id,
        lead: this.props.chat_user?.type === 2 && this.props.user.type === 2,
      }));
    }
  }

  onScroll = (e) => {
    // console.log(e.currentTarget.scrollTop);
    this.setState({
      ...this.state,
      scroll_height: this.messagesWrapper.scrollHeight,
    });
    // load new messages
    if (
      this.props.chat_messages[this.props.chat_id] &&
      this.props.chat_messages[this.props.chat_id][0] &&
      this.props.chat_messages[this.props.chat_id][0].message_id &&
      this.messagesWrapper.scrollTop === 0
    ) {
      this.props.getChatMessages(this.props.chat_id, this.props.chat_messages[this.props.chat_id][0].message_id);
    }
  };

  jump_to_last_message = () => {
    this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
  };

  onFieldChange = (e) => {
    // Textarea Auto height
    let el = e.target;
    el.style.cssText = "height:" + el.scrollHeight + "px";

    // update state
    this.props.updateMessageInput({
      name: e.target.name,
      value: e.target.value,
    });
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  onSubmit = () => {
    const message = this.props.message_input.value || '';
    const files = this.state.selected_files;

    if (!message && !files.length) {
      return;
    }

    this.props.sendMessage(message, this.props.chat_id, files);

    let chat_users = this.props.chat_id.split(":").map(Number);
    console.log('===CHAT USER', chat_users);
    let current_user_id = this.props.user.id;
    let opponent_id = chat_users.filter((item) => item !== current_user_id);
    let receiver_id = this.props.chat_user.company_id ? this.props.chat_user.company_id : opponent_id[0];

    this.props.socketSendMessage(receiver_id, this.props.chat_id, message);
    this.props.updateMessageInput("");
    this.clearFiles();
    setTimeout(this.jump_to_last_message, 400);
  };

  selectFileHandler = (e) => {
    if (e.target.files.length) {
      const newFileData = e.target.files[0];

      let error;
      const totalFiles = this.props.today_files + this.state.selected_files.length + 1;
      const extension = newFileData.name.split('.').pop().toLowerCase();
      const existenceFile = this.state.selected_files.find(fileData => fileData.name === newFileData.name);

      if (newFileData.size > fileSizeLimitInBytes) {
        error = `Maximum file limit is ${chatFileSizeLimit}MB`;
      } else if (totalFiles > chatFilesLimit) {
        error = `Maximum day limit is ${chatFilesLimit} files`;
      } else if (!chatFileExtensions.includes(extension)) {
        error = `The file extension must be: ${chatFileTypesExtensionList}`;
      } else if (existenceFile) {
        error = "A file with the same name has already been selected";
      }

      if (error) {
        e.target.value = null;

        return notification.error({
          message: error,
          placement: "topRight",
          duration: 2,
        });
      }

      const selectedFiles = [...this.state.selected_files, e.target.files[0]];
      this.setState({
        ...this.state,
        selected_files: selectedFiles,
      });
      e.target.value = null;
    }
  };

  deleteFileHandler = (index) => {
    const selectedFiles = [...this.state.selected_files];
    selectedFiles.splice(index, 1);

    this.setState({
      ...this.state,
      selected_files: selectedFiles,
    });
  };

  clearFiles = () => {
    this.setState({
      ...this.state,
      selected_files: [],
    });
  };

  render() {
    let messages = this.props.chat_messages[this.props.chat_id];
    let groupedMessagesByDay = groupByTime(messages, "created", "day");

    let groupedMessages = [];

    Object.keys(groupedMessagesByDay).map((groupKey) => {
      const groupedMessagesByMinute = [];

      groupedMessagesByDay[groupKey].forEach((message) => {
        // Get integer timestamp with seconds and milliseconds set to 0 (to group by minutes)
        let time = new Date(message.created);
        time.setSeconds(0);
        time.setMilliseconds(0);
        let createdAtMinutes = time.getTime();

        // Try to get existing message group
        let currentGroup = groupedMessagesByMinute.filter((msgGrp) => msgGrp.created === createdAtMinutes);

        // If we've got the existing group, add the message, otherwise create a new group
        if (currentGroup.length) {
          currentGroup[0].messages.push(message);
        } else {
          groupedMessagesByMinute.push({ created: createdAtMinutes, messages: [message] });
        }
      });

      groupedMessages[groupKey] = groupedMessagesByMinute;
    });

    return (
      <div className={styles.chat_messages_wrapper}>
        <div
          className={styles.messages_list_wrapper}
          ref={(el) => {
            this.messagesWrapper = el;
          }}
          onScroll={this.onScroll}
        >
          <div className={styles.messages_list}>
            {Object.keys(groupedMessages).map((groupKey) => (
              <>
                <div className={styles.messagesDate}>{moment(Number(groupKey)).format("DD MMM, YYYY")}</div>

                {groupedMessages[groupKey].map((groupedMessagesByTime) => (
                  <>
                    <div className={styles.messagesTime}>
                      {moment(Number(groupedMessagesByTime.created)).format("HH:mm A")}
                    </div>

                    {groupedMessagesByTime.messages.map((message) => (
                      <Message
                        key={message.message_id}
                        user={this.props.user}
                        chat_user={this.props.chat_user}
                        created={message.created}
                        message={message.message}
                        author_id={message.author_id}
                        status={message.status}
                        files={message.files}
                      />
                    ))}
                  </>
                ))}
              </>
            ))}
          </div>
        </div>

        <div className={styles.message_input_wrapper}>
          <textarea
            id="message_input"
            name="message_input"
            rows="2"
            className={`form_control ${styles.message_input}`}
            value={this.props.message_input && this.props.message_input.value}
            placeholder="Type a message..."
            onChange={this.onFieldChange}
            onKeyDown={this.onEnterPress}
          />
          <span
            onClick={this.jump_to_last_message}
            className={styles.scroll_bottom}
            title="Jump to last message"
          />

          <button onClick={this.onSubmit} className={styles.send_message} title="Send message">
            Send message
          </button>
        </div>
        <div className={styles.download_section}>
          <div className={styles.download_info}>
            <div className={styles.download_button_row}>
              <label className={styles.add_file}>
                <div className={styles.add_file_button} />
                <input
                  type="file"
                  accept={chatFileInputAccept}
                  className={styles.file_input}
                  onChange={this.selectFileHandler}
                />
              </label>
              <div>{`${chatFileTypesExtensionList} Size<${chatFileSizeLimit}MB`}</div>
            </div>
            <div className={styles.download_info_row}>
              <span className={styles.bold}>Day limit:</span> {chatFilesLimit} files
            </div>
            <div className={styles.download_info_row}>
              <span className={styles.bold}>Today:</span> {this.props.today_files} files
            </div>
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.helpText}>All links must start with http:// or https://</div>
            <div className={styles.files_list}>
              {this.state.selected_files.map((fileData, index) => (
                <div className={styles.file_item} key={index}>
                  <div className={styles.file_name}>
                    {fileData.name}
                  </div>
                  <div className={styles.delete_file} onClick={() => this.deleteFileHandler(index)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatMessages;
