import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  getPopularServicesList,
  getPopularServicesTopCompanies,
  addViewBannerAnalytic,
  addClickBannerAnalytic,
  addChooseServiceAnalytic,
  addViewServiceCompaniesAnalytic,
  addClickServiceCompanyAnalytic,
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
  getSettings,
} from "../../store/app";

import FrontPage from "./FrontPage";

const defaultPopularServiceId = 5;

const FrontPageContainer = (props) => {
  useEffect(() => {
    props.getPopularServicesList();
    props.getSettings();
  }, []);

  return (
    <FrontPage
      {...props}
      defaultPopularServiceId={defaultPopularServiceId}
    />
  );
};

let mapStateToProps = (state) => ({
  popularServices: state.app.popular_services_list,
  popularServicesTopCompanies: state.app.popular_services_top_companies,
  stats: state.app.stats,
  settings: state.app.settings,
});

const mapDispatchToProps = {
  getPopularServicesList,
  getPopularServicesTopCompanies,
  addViewBannerAnalytic,
  addClickBannerAnalytic,
  addChooseServiceAnalytic,
  addViewServiceCompaniesAnalytic,
  addClickServiceCompanyAnalytic,
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
  getSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(FrontPageContainer);
