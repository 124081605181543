import { activityStatsAPI } from "../api/activityStats";
import { skillsAPI } from "../api/skills";
import { marketsAPI } from "../api/markets";
import { publicAPI } from "../api/public";
import { developersAPI } from "../api/developers";
import { getCompanyData, getCompanyVerificationChecks } from "./auth";
import { getBannersList } from "./banners";
import { getDevelopers, getPositionLevels } from "./developer";
import { getCasesList } from "./cases";
import { analyticEvents } from "../constants";

const THEME = "ibench/app/THEME";
const INITIALIZED_SUCCESS = "ibench/app/INITIALIZED_SUCCESS";
const STATS = "ibench/app/STATS";
const ACTIVITY_STATS = "ibench/app/ACTIVITY_STATS";
const NEW_COMPANIES = "ibench/app/NEW_COMPANIES";
const NEW_STARTUPS = "ibench/app/NEW_STARTUPS";
const SKILLS_LIST = "ibench/app/SKILLS_LIST";
const MARKETS_LIST = "ibench/app/MARKETS_LIST";
const JOB_TITLES_LIST = "ibench/app/JOB_TITLES_LIST";
const LANGUAGE_LEVELS_LIST = "ibench/app/LANGUAGE_LEVELS_LIST";
const INDUSTRIES_LIST = "ibench/app/INDUSTRIES_LIST";
const INDUSTRIES_TOP_COMPANIES = "ibench/app/INDUSTRIES_TOP_COMPANIES";
const POPULAR_SERVICES_LIST = "ibench/app/POPULAR_SERVICES_LIST";
const POPULAR_SERVICES_TOP_COMPANIES = "ibench/app/POPULAR_SERVICES_TOP_COMPANIES";
const PROJECT_AREAS = "ibench/app/PROJECT_AREAS";
const RANDOM_DEVELOPERS = "ibench/app/RANDOM_DEVELOPERS";
const TEAM_REQUEST_MESSAGE = "ibench/app/TEAM_REQUEST_MESSAGE";
const SET_SETTINGS = "ibench/app/SET_SETTINGS";

let initialState = {
  theme: "default_theme",
  initialized: false,
  stats: null,
  activity_stats: null,
  newCompanies: null,
  newStartups: null,
  skills_list: [],
  markets_list: [],
  industries_list: [],
  job_titles_list: [],
  language_level_list: [],
  position_level_list: [],
  industries_top_companies: [],
  popular_services_list: [],
  popular_services_top_companies: [],
  project_areas: [],
  random_developers: null,
  team_request_message: null,
  settings: {},
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case THEME:
      return { ...state, theme: action.payload };
    case INITIALIZED_SUCCESS:
      return { ...state, initialized: true };
    case STATS:
      return { ...state, stats: action.payload };
    case ACTIVITY_STATS:
      return { ...state, activity_stats: action.payload };
    case NEW_COMPANIES:
      return { ...state, newCompanies: action.payload };
    case NEW_STARTUPS:
      return { ...state, newStartups: action.payload };
    case SKILLS_LIST:
      return { ...state, skills_list: action.payload };
    case MARKETS_LIST:
      return { ...state, markets_list: action.payload };
    case JOB_TITLES_LIST:
      return { ...state, job_titles_list: action.payload };
    case LANGUAGE_LEVELS_LIST:
      return { ...state, language_level_list: action.payload };
    case INDUSTRIES_LIST:
      return { ...state, industries_list: action.payload };
    case INDUSTRIES_TOP_COMPANIES:
      return { ...state, industries_top_companies: action.payload };
    case POPULAR_SERVICES_LIST:
      return { ...state, popular_services_list: action.payload };
    case POPULAR_SERVICES_TOP_COMPANIES:
      return { ...state, popular_services_top_companies: action.payload };
    case PROJECT_AREAS:
      return { ...state, project_areas: action.payload };
    case RANDOM_DEVELOPERS:
      return { ...state, random_developers: action.payload };
    case TEAM_REQUEST_MESSAGE:
      return { ...state, team_request_message: action.payload };
    case SET_SETTINGS:
      return { ...state, settings: action.payload };

    default:
      return state;
  }
};

export const setTheme = (payload) => ({ type: THEME, payload });

const initializedSuccess = () => ({ type: INITIALIZED_SUCCESS });

export const initializeApp = () => (dispatch) => {
  let statsPromise = dispatch(getStats());
  let skillsListPromise = dispatch(getSkillsList());
  let marketsListPromise = dispatch(getMarketsList());

  const promises = [statsPromise, skillsListPromise, marketsListPromise];

  let positionLevelsPromise = dispatch(getPositionLevels());
  promises.push(positionLevelsPromise);

  let user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    let authUserDataPromise = dispatch(getCompanyData(user.token));
    promises.push(authUserDataPromise);

    promises.push(dispatch(getBannersList()));

    promises.push(dispatch(getCompanyVerificationChecks(user.is_single_developer)));
    promises.push(dispatch(getDevelopers()));
    promises.push(dispatch(getCasesList()));
  }

  Promise.all(promises).then(() => {
    dispatch(initializedSuccess());
  });
};

const setStats = (payload) => ({ type: STATS, payload });

// GET PROJECT STATS
export const getStats = () => {
  return (dispatch) => {
    publicAPI.getStats().then((response) => {
      dispatch(setStats(response.data.statistic));
      // dispatch(setRandomDevelopers(response.data.developers));
    });
  };
};

const setActivityStats = (payload) => ({ type: ACTIVITY_STATS, payload });
// GET ACTIVITY STATS
export const getActivityStats = () => {
  return (dispatch) => {
    activityStatsAPI.getStats().then((response) => {
      dispatch(setActivityStats(response.data.stats));
    });
  };
};

const setNewCompanies = (payload) => ({ type: NEW_COMPANIES, payload });
export const getNewCompanies = () => {
  return (dispatch) => {
    publicAPI.getNewCompanies().then((response) => {
      dispatch(setNewCompanies(response.data));
    });
  };
};

const setNewStartups = (payload) => ({ type: NEW_STARTUPS, payload });
export const getNewStartups = () => {
  return (dispatch) => {
    publicAPI.getNewStartups().then((response) => {
      dispatch(setNewStartups(response.data));
    });
  };
};

const setSkillsList = (payload) => ({ type: SKILLS_LIST, payload });
export const getSkillsList = () => {
  return (dispatch) => {
    skillsAPI.getPublicSkillsList().then((response) => {
      dispatch(setSkillsList(response.data.result));
    });
  };
};

const setMarketsList = (payload) => ({ type: MARKETS_LIST, payload });
export const getMarketsList = () => {
  return (dispatch) => {
    marketsAPI.getPublicMarketsList().then((response) => {
      dispatch(setMarketsList(response.data.result));
    });
  };
};

const setJobTitlesList = (payload) => ({ type: JOB_TITLES_LIST, payload });
export const getJobTitlesList = () => {
  return (dispatch) => {
    developersAPI.getJobTitles().then((response) => {
      dispatch(setJobTitlesList(response));
    });
  };
};

const setLanguageLevelsList = (payload) => ({ type: LANGUAGE_LEVELS_LIST, payload });
export const getLanguageLevelsList = () => {
  return (dispatch) => {
    developersAPI.getLanguageLevels().then((response) => {
      dispatch(setLanguageLevelsList(response));
    });
  };
};

const setTeamRequestMessage = (payload) => ({ type: TEAM_REQUEST_MESSAGE, payload });
export const sendTeamRequest = (data) => {
  return (dispatch) => {
    publicAPI.requestTeam(data).then((response) => {
      dispatch(setTeamRequestMessage(response.data.message));
    });
  };
};

const setIndustriesList = (payload) => ({ type: INDUSTRIES_LIST, payload });
export const getIndustriesList = () => {
  return (dispatch) => {
    publicAPI.getIndustriesList().then((response) => {
      dispatch(setIndustriesList(response.industries));
    });
  };
};

const setIndustriesTopCompanies = (payload) => ({ type: INDUSTRIES_TOP_COMPANIES, payload });
export const getIndustriesTopCompanies = (industryId) => {
  return (dispatch) => {
    publicAPI.getIndustriesTopCompanies(industryId).then((response) => {
      dispatch(setIndustriesTopCompanies(response.top_companies));
    });
  };
};

const setPopularServicesList = (payload) => ({ type: POPULAR_SERVICES_LIST, payload });
export const getPopularServicesList = () => {
  return (dispatch) => {
    publicAPI.getPopularServicesList().then((response) => {
      dispatch(setPopularServicesList(response.popular_services));
    });
  };
};

const setPopularServicesTopCompanies = (payload) => ({ type: POPULAR_SERVICES_TOP_COMPANIES, payload });
export const getPopularServicesTopCompanies = (popularServiceId) => {
  return (dispatch) => {
    publicAPI.getPopularServicesTopCompanies(popularServiceId).then((response) => {
      dispatch(setPopularServicesTopCompanies(response.top_companies));
    });
  };
};

const setProjectAreas = (payload) => ({ type: PROJECT_AREAS, payload });
export const getProjectAreas = () => (dispatch) => {
  publicAPI.getProjectAreas().then((response) => {
    dispatch(setProjectAreas(response.project_areas));
  });
};

export const addViewBannerAnalytic = () => {
  return () => {
    const analyticData = {
      event: analyticEvents.VIEW_BANNER
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickBannerAnalytic = () => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_BANNER
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addChooseServiceAnalytic = (serviceId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CHOOSE_SERVICE_IN_CALCULATOR,
      source_id: serviceId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addViewIndustryCompaniesAnalytic = (industryId, companies) => {
  return () => {
    const analyticData = {
      event: analyticEvents.VIEW_INDUSTRY_COMPANY,
      source_id: industryId,
      companies,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addViewServiceCompaniesAnalytic = (serviceId, companies) => {
  return () => {
    const analyticData = {
      event: analyticEvents.VIEW_SERVICE_COMPANY,
      source_id: serviceId,
      companies,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickIndustryCompanyAnalytic = (industryId, companyId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_INDUSTRY_COMPANY,
      source_id: industryId,
      company_id: companyId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickServiceCompanyAnalytic = (serviceId, companyId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_SERVICE_COMPANY,
      source_id: serviceId,
      company_id: companyId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickServiceCompanyTelegramAnalytic = (companyId, serviceId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_SERVICE_COMPANY_TELEGRAM,
      source_id: serviceId,
      company_id: companyId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickServiceCompanyWhatsappAnalytic = (companyId, serviceId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_SERVICE_COMPANY_WHATSAPP,
      source_id: serviceId,
      company_id: companyId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

export const addClickServiceCompanyEmailAnalytic = (companyId, serviceId) => {
  return () => {
    const analyticData = {
      event: analyticEvents.CLICK_SERVICE_COMPANY_EMAIL,
      source_id: serviceId,
      company_id: companyId,
    };

    publicAPI.addAnalytic(analyticData);
  };
};

const setSettingsList = payload => ({ type: SET_SETTINGS, payload });

export const getSettings = () => (dispatch) => {
  publicAPI.getSettings().then((data) => {
    dispatch(setSettingsList(data.settings));
  });
};

export default app;
